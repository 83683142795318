<template>
  <div class="customer__email">
    <template v-if="!data.submittedSuccessfully">
      <Form
        id="customer_email"
        method="post"
        action="/contact#contact_form"
        accept-charset="UTF-8"
        class="customer__email__form"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <input type="hidden" name="form_type" value="customer" />
        <input type="hidden" name="utf8" value="✓" />

        <div class="customer__email__input__container">
          <v-text-input
            :value="data.form.contactEmailAddress"
            :disabled="data.disabled"
            id="email"
            class="box"
            type="email"
            name="contact[email]"
            placeholder="Your email"
            :required="true"
          />
          <button>
            <input
              type="submit"
              value=""
              class="hidden"
            /><icon-chevron-right />
          </button>
        </div>
      </Form>
    </template>
    <template v-else>
      <div class="customer__email__validation">
        <icon-check fill="#303030" />
        <p class="accent">Thanks you are subscribed.</p>
      </div>
    </template>
  </div>
</template>

<script setup>
import { reactive } from 'vue';
import * as Yup from 'yup';
import { useForm, Form, Field, ErrorMessage } from 'vee-validate';
import VTextInput from './VTextInput.vue';
import IconChevronRight from '../icons/IconChevronRight.vue';
import IconCheck from '../icons/IconCheck.vue';

const data = reactive({
  disabled: false,
  submittedSuccessfully: false,
  form: {
    contactEmailAddress: '',
  },
});

const schema = Yup.object().shape({
  'contact[email]': Yup.string()
    .email('Email is not valid')
    .required('Email is required.'),
});

const onInvalidSubmit = () => {
  data.disabled = true;
  setTimeout(() => {
    data.disabled = false;
  }, 1500);
};

if (
  window.location.href.includes('customer_posted=true') ||
  window.location.href.includes('form_type=customer')
) {
  data.submittedSuccessfully = !data.submittedSuccessfully;
}
</script>

<style lang="scss">
.contact-form {
  padding: 3.125rem 1.25rem;
  background-color: var(--light-grey);
  border-top: thin solid var(--grey-4);

  &__success-message p {
    text-align: center;
    color: var(--grey-1);
  }

  h3 {
    margin-bottom: 1.875rem;
    font-size: 1.125rem;
    line-height: var(--line-height-md);
    text-align: center;
  }

  h4 {
    text-align: center;
    text-transform: uppercase;
  }

  form {
    max-width: 50.625rem;
    margin: auto;
  }

  .selector {
    width: 100%;
    padding: 0.625rem 0.9375rem;
    margin-bottom: 1rem;
    border: thin solid var(--grey-1);

    .placeholder {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
    }

    .options-dropdown {
      margin-top: 0.5rem;
      margin-bottom: 0 !important;

      input {
        width: 0;
        height: 0;
      }
    }
  }

  textarea {
    background-color: transparent;
  }

  .form-submit {
    text-align: center;
  }

}
</style>
