/**
 * imports
 */
import 'vite/modulepreload-polyfill';
import { createApp, defineComponent, onMounted, reactive } from 'vue';
import CustomerEmail from '../components/render/form/CustomerEmail.vue';
import TransitionExpand from '../components/render/transitions/TransitionExpand.vue';
import { useScreen, useGrid } from 'vue-screen';

/**
 * create vue instance function
 */
const createVueApp = () => {
  const app = createApp({
    setup() {
      defineComponent({
        components: {
          TransitionExpand,
        },
      });

      const screen = useScreen();
      const grid = useGrid({
        mobile: '416px',
        tablet: '640px',
        'tablet-lg': '1024px',
        laptop: '1152px',
        desktop: '1460px',
        ultrawide: '2000px',
      });
      const menuStates = reactive({
        showFirstMenu: false,
        showSecondMenu: false,
        showThirdMenu: false,
      });

      const toggleNav = (menu) => {
        if (menu == 'first') {
          menuStates.showFirstMenu = !menuStates.showFirstMenu;
        } else if (menu == 'second') {
          menuStates.showSecondMenu = !menuStates.showSecondMenu;
        } else if (menu == 'third') {
          menuStates.showThirdMenu = !menuStates.showThirdMenu;
        }
      };

      return {
        screen,
        grid,
        menuStates,
        toggleNav,
      };
    },
  });

  return app;
};

/**
 * create and mount vue instance(s)
 */
const footer = document.querySelector('.vue__footer');

if (footer) {
  createVueApp({})
    .component('CustomerEmail', CustomerEmail)
    .component('TransitionExpand', TransitionExpand)
    .mount(footer);
}

/**
 * fixes for Shopify sections
 * 1. properly render vue components on user insert in the theme editor
 * 2. reload the current page to rerender async inserted sections with vue components
 *
 * add the 'vue' keyword to the section's wrapper classes if the section uses any vue functionality e.g.:
 * {% schema %}
 * {
 *   "class": "vue-section"
 * }
 * {% endschema %}
 */
if (window.Shopify.designMode) {
  document.addEventListener('shopify:section:load', (event) => {
    if (event.target.classList.value.includes('vue')) {
      createVueApp().mount(event.target);
    }
  });
} else if (
  !window.Shopify.designMode &&
  process.env.NODE_ENV === 'development'
) {
  new window.MutationObserver((mutationsList) => {
    mutationsList.forEach((record) => {
      const vue = Array.from(record.addedNodes).find(
        (node) => node.classList && node.classList.value.includes('vue')
      );
      if (vue) window.location.reload();
    });
  }).observe(document.body, {
    childList: true,
    subtree: true,
  });
}
