<template>
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 11L6 6L1 1" stroke="#303030" stroke-width="1.5" />
  </svg>
</template>
